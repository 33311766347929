// Define BreakPoint Sizes
$screen-xs-min: 320px;
$screen-sm-min: 481px;
$screen-md-min: 769px;
$screen-lg-min: 1025px;
$screen-xl-min: 1201px;


// Extra Small
@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

// Small
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra Large
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

* {
  box-sizing: border-box;
}

.container {
  margin: auto;
  width: 100%;
  min-height: 100vh;
  padding: 5px;
  background-color: lightgrey;

  @include xs {
    width: 320px;
  }
  @include sm {
    width: 481px;
  }
  @include md {
    width: 769px;
  }
  @include lg {
    width: 1025px;
  }
}
.header {
  min-height: 60px;
  border: 1px solid grey;
  background-color: lightblue;
}

.footer {
  text-align: right;
  padding-right: 1rem;
  min-height: 60px;
  background-color: bisque;
  border: 1px solid grey
}

.content{
  height: calc(100vh - 60px);
  border: 1px solid grey;
}


.sidebar {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  min-height: auto;
  float: left;
  border: 1px solid grey;
  background-color: lightgreen;

  @include sm {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100px;
    height: 100%;
  }
}


.center {
  text-align: right;
}
